<template>
  <div class="DoemHOme">
    <div class="bg" v-show="isView"></div>
    <div class="logo"></div>
    <div id="initScene"></div>
    <div id="Canvas" ref="content">
      <div class="topLine"></div>
      <div class="bootLine"></div>
    </div>
    <div
      :class="`navItem navItem${i}`"
      :ref="item.YText"
      v-for="(item, i) in navData"
      :key="i"
      @mouseover="mouseOver(item)"
      @mouseleave="mouseLeave(item)"
    >
      <div class="title">
        <span class="YText">{{ item.YText }}</span>
        <span class="ZText">{{ item.ZText }}</span>
      </div>
      <div
        :class="`childItem childItem${ii}`"
        @click="goto(childItem.id)"
        v-for="(childItem, ii) in item.Data"
        :key="ii"
      >
        <div class="title">
          <span class="YText">{{ childItem.YText }}</span>
        </div>
        <p class="ZText">{{ childItem.ZText }}</p>
        <div class="loader">
          <div class="face">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import * as dat from "dat.gui";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
export default {
  data() {
    return {
      isView: false,
      navData: [
        {
          YText: "Safety",
          ZText: "太空安全",
          Data: [
            {
              id: 4,
              YText: "Caution",
              ZText: "太空碰撞预警",
            },
            {
              id: 2,
              YText: "Transat",
              ZText: "太空过境预报",
            },
            {
              id: 8,
              YText: "Monitoring",
              ZText: "太空目标波束监测",
            },
          ],
        },
        {
          YText: "Target",
          ZText: "太空目标",
          Data: [
            {
              id: 5,
              YText: "Track",
              ZText: "太空目标轨迹溯源",
            },
            // {
            //   id: 5,
            //   YText: 'Simulation',
            //   ZText: '太空目标轨道仿真',
            // },
            {
              id: 6,
              YText: "Link",
              ZText: "太空目标星地链接",
            },
            {
              id: 7,
              YText: "Point",
              ZText: "太空目标星下点轨迹",
            },
          ],
        },
        {
          YText: "Situation",
          ZText: "太空态势",
          Data: [
            {
              id: 1,
              YText: "Situation",
              ZText: "太空态势感知",
            },
            {
              id: 0,
              YText: "Data",
              ZText: "太空编目数据",
            },
          ],
        },
      ],
      // 创建一个场景
      scene: null,
      // 创建一个相机
      camera: null,
      // 创建一个渲染器
      renderer: null,
      // 模型对象
      mesh: null,
      // 平面
      plane: null,
      // 点光源
      point: null,
      // step
      step: 0,
      controls: null,
    };
  },
  mounted() {
    var token = sessionStorage.getItem("token");
    if (!token) {
      this.isView = true;
      this.$alert("您当前不具备访问权限，请向上级获取账户信息登陆后访问", "提示", {
        confirmButtonText: "确定",
        callback: () => {
          this.$router.push("./login");
        },
      });
    } else {
      this.init();
    }
  },
  methods: {
    // 初始化
    init() {
      // 初始化容器
      var content = this.$refs.content;
      // 创建一个场景
      this.scene = new THREE.Scene();
      // 创建几何体
      var geometry = new THREE.SphereGeometry(40, 60, 60);
      // 纹理加载器 （ 此处加载贴图 ）
      var texture = new THREE.TextureLoader().load(require("../assets/newImg/24.png"));
      // var texture = new THREE.TextureLoader().load(require('../assets/newImg/2.png'));
      // 几何体材质对象
      var material = new THREE.MeshLambertMaterial({
        map: texture,
      });
      // 创建网格模型对象
      this.mesh = new THREE.Mesh(geometry, material);
      //设置几何体位置
      this.mesh.position.x = 0;
      this.mesh.position.y = 0;
      this.mesh.position.z = 0;
      this.scene.add(this.mesh);

      // 聚光灯
      let spotLight = new THREE.SpotLight(0xffffff, 6, 0); //创建光源
      // 光源移动
      spotLight.position.set(-20, 40, -20);
      this.scene.add(spotLight); //在场景中添加光源
      let spotLightB = new THREE.SpotLight(0xfee69f, 6, 0); //创建光源
      spotLightB.position.set(-20, -18, 50);
      this.scene.add(spotLightB); //在场景中添加光源

      // 创建点光源
      var point = new THREE.PointLight("#FFF");
      point.position.set(0, 40, 80);
      this.point = point;
      this.scene.add(point);
      const sphereSize = 10;
      const pointLightHelper = new THREE.PointLightHelper(point, sphereSize);
      this.scene.add(pointLightHelper);

      //创建环境光
      var ambient = new THREE.AmbientLight(0x444444);
      this.scene.add(ambient);

      // 创建一个相机
      this.camera = new THREE.PerspectiveCamera(
        70,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );
      this.camera.position.set(-50, 50, 50);
      this.camera.lookAt(0, 0, 0);

      // 创建渲染器
      this.renderer = new THREE.WebGLRenderer({ alpha: true });
      // 设置alpha透明系数
      this.renderer.setClearAlpha(0);
      // this.renderer.setSize(content.style.clientWidth, content.style.clientHeight);
      this.renderer.setSize(content.clientWidth, content.clientHeight);
      // this.renderer.setClearColor(0xb9d3ff, 1);
      //插入 dom 元素
      content.appendChild(this.renderer.domElement);
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      //禁用缩放
      this.controls.enableZoom = false;
      //禁用旋转
      this.controls.enableRotate = false;
      //禁用平移
      this.controls.enablePan = false;
      this.controls.addEventListener("resize", this.render(), false);
    },
    render() {
      this.renderer.render(this.scene, this.camera);
      // 自动旋转动画
      this.mesh.rotateY(0.002);
      requestAnimationFrame(this.render);
    },
    goto(num) {
      if (window.location.hostname == "39.107.241.137") {
        if (OnGoUrl[num]) {
          window.open(`${OnGoUrl[num]}?token=${sessionStorage.getItem("token")}`);
        }
      } else {
        if (LocalGoUrl[num]) {
          window.open(`${LocalGoUrl[num]}?token=${sessionStorage.getItem("token")}`);
        }
      }
    },
    // goto (num) {
    //   if (num == 0) {
    //     window.open('http://192.168.2.163:31017/logo/index.html')
    //   } else if (num == 1) {
    //     // 态势感知
    //     // window.open('http://192.168.2.163:31017/taishi/index.html#/')
    //     window.open('http://192.168.2.145:7104/tsgz/index.html#/')
    //   } else if (num == 2) {
    //     // 过境预报
    //     window.open('http://192.168.2.163:9093/#/')
    //   } else if (num == 3) {
    //     // 空间安全
    //     // window.open('http://192.168.2.163:31017/pengzhuang/index.html#/')
    //     window.open('http://192.168.2.145:7102/pzyj/#/')
    //   } else if (num == 4) {
    //     // 空间环境
    //     window.open('http://192.168.2.223/')
    //   } else if (num == 5) {
    //     // 轨道仿真
    //     // window.open('http://192.168.2.163:31017/kepler/index.html#/', "newwindow",)
    //     // window.open('http://192.168.2.163:31017/kepler/index.html#/Plantrack', "newwindow2",)
    //     window.open('http://192.168.2.163:31017/webkepler/index.html#/')
    //   } else if (num == 6) {
    //     // 在轨状态
    //     window.open('http://192.168.2.145:7100/wxbs/index.html#/zgzt')
    //   } else if (num == 7) {
    //     // 卫星波束
    //     window.open('http://192.168.2.145:7100/wxbs/index.html#/wxbs')
    //   } else if (num == 8) {
    //     // 星地链接
    //     window.open('http://192.168.2.145:7100/wxbs/index.html#/xdlj')
    //   } else if (num == 9) {
    //     // 星下点
    //     window.open('http://192.168.2.145:7100/wxbs/index.html#/xxd')
    //   }
    // },
    mouseOver(val) {
      switch (val.YText) {
        case "Safety":
          this.$refs.Target[0].style.background = `url("${require("@/assets/newImg/19.png")}") no-repeat`;
          this.$refs.Target[0].style.backgroundSize = "100% 100%";
          this.$refs.Situation[0].style.background = `url("${require("@/assets/newImg/20.png")}") no-repeat`;
          this.$refs.Situation[0].style.backgroundSize = "100% 100%";
          break;
        case "Target":
          this.$refs.Safety[0].style.background = `url("${require("@/assets/newImg/18.png")}") no-repeat`;
          this.$refs.Safety[0].style.backgroundSize = "100% 100%";
          this.$refs.Situation[0].style.background = `url("${require("@/assets/newImg/20.png")}") no-repeat`;
          this.$refs.Situation[0].style.backgroundSize = "100% 100%";
          break;
        case "Situation":
          this.$refs.Safety[0].style.background = `url("${require("@/assets/newImg/18.png")}") no-repeat`;
          this.$refs.Safety[0].style.backgroundSize = "100% 100%";
          this.$refs.Target[0].style.background = `url("${require("@/assets/newImg/19.png")}") no-repeat`;
          this.$refs.Target[0].style.backgroundSize = "100% 100%";
          break;
        default:
          break;
      }
    },
    mouseLeave(val) {
      switch (val.YText) {
        case "Safety":
          this.$refs.Target[0].style.background = `url("${require("@/assets/newImg/16.png")}") no-repeat`;
          this.$refs.Target[0].style.backgroundSize = "100% 100%";
          this.$refs.Situation[0].style.background = `url("${require("@/assets/newImg/17.png")}") no-repeat`;
          this.$refs.Situation[0].style.backgroundSize = "100% 100%";
          break;
        case "Target":
          this.$refs.Safety[0].style.background = `url("${require("@/assets/newImg/15.png")}") no-repeat`;
          this.$refs.Safety[0].style.backgroundSize = "100% 100%";
          this.$refs.Situation[0].style.background = `url("${require("@/assets/newImg/17.png")}") no-repeat`;
          this.$refs.Situation[0].style.backgroundSize = "100% 100%";
          break;
        case "Situation":
          this.$refs.Safety[0].style.background = `url("${require("@/assets/newImg/15.png")}") no-repeat`;
          this.$refs.Safety[0].style.backgroundSize = "100% 100%";
          this.$refs.Target[0].style.background = `url("${require("@/assets/newImg/16.png")}") no-repeat`;
          this.$refs.Target[0].style.backgroundSize = "100% 100%";
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
@import url("./css/newv1.less");

.isGcontainer {
  background: url("~@/assets/newStyle/k1.png") no-repeat !important;
  background-size: 100% 100% !important;
  transform: scale(1.05) !important;
}
</style>

<style>
.dg.ac {
  display: none;
}
</style>
