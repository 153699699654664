<!--
 * @Author: Suroc
 * @Date: 2024-05-29 14:03:37
 * @LastEditTime: 2024-06-03 17:43:38
 * @Description: 特种领域
-->
<template>
  <div class="specialView">
    <a :href="item.path" target="_blank" class="navActive" :class="`navItem${I}`" v-for="(item, I) in navList" :key="I">
      <p>{{ item.name }}</p>
    </a>
  </div>
</template>
<script>
export default {
  data () {
    return {
      navList: [
        {
          name: "太空资产碰撞分析",
          path: "https://ads.aseem.cn/pzyj/#/",
        },
        {
          name: "太空资产轨迹溯源",
          path: "https://ads.aseem.cn/digit/gjsy",
        },
        {
          name: "太空资产过境预报",
          path: "https://ads.aseem.cn/transit/#/",
        },
        {
          name: "太空资产星下点轨迹",
          path: "https://ads.aseem.cn/digit/xxd",
        },
        {
          name: "太空资产过境预报V2",
          path: "https://ads.aseem.cn/visualization/transit/v2/",
        },
        {
          name: "太空资产态势分析",
          path: "https://ssa.aseem.cn/",
        },
        {
          name: "太空资产过境预报V3",
          path: "https://ads.aseem.cn/visualization/transit/v3/",
        },
        {
          name: "太空资产编目数据",
          path: "https://ads.aseem.cn/tsgz/#/",
        },
        {
          name: "太空资产波束检测",
          path: "https://ads.aseem.cn/digit/bsjc",
        },
        {
          name: "太空资产碰撞热力图",
          path: "https://ads.aseem.cn/visualization/approach/v1",
        },
      ],
    };
  },
  mounted () { },
  methods: {},
};
</script>

<style scoped lang="less">
.specialView {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("~@/assets/special/101.png") no-repeat center center;
  background-size: 100% 100%;
  position: relative;

  .navActive {
    width: 340px;
    height: 102px;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    // opacity: 0;
    z-index: 2;

    p {
      font-size: 20px;
      font-weight: 700;
      line-height: 114px;
      text-align: center;
      -webkit-text-fill-color: transparent;
      background: linear-gradient(180deg, #fdfffe 0%, #69caf7 100%);
      -webkit-background-clip: text;
    }

    &:hover {
      transform: scale(1.06);

      p {
        -webkit-text-fill-color: transparent;
        background: linear-gradient(180deg, #00ffaa 0%, #00ffff 100%);
        -webkit-background-clip: text;
      }
    }
  }

  .navItem0 {
    left: 16%;
    top: 18%;
    background: url("~@/assets/special/1.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem0Frame 2s forwards;

    // &:hover {
    //   background: url("~@/assets/special/11.png") no-repeat;
    //   background-size: 100% 100%;
    //   background-position: top;
    // }
  }
  .navItem1 {
    right: 16%;
    top: 18%;
    background: url("~@/assets/special/2.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem1Frame 2s forwards;

    // &:hover {
    //   background: url("~@/assets/special/22.png") no-repeat;
    //   background-size: 100% 100%;
    //   background-position: to;
    // }
  }
  .navItem2 {
    left: 12%;
    top: 30%;
    background: url("~@/assets/special/3.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem2Frame 2s forwards;

    // &:hover {
    //   background: url("~@/assets/special/33.png") no-repeat;
    //   background-size: 100% 100%;
    //   background-position: top
    // }
  }
  .navItem3 {
    right: 12%;
    top: 30%;
    background: url("~@/assets/special/4.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem3Frame 2s forwards;

    // &:hover {
    //   background: url("~@/assets/special/44.png") no-repeat;
    //   background-size: 100% 100%;
    //   background-position: top;
    // }
  }
  .navItem4 {
    left: 10%;
    top: 42%;
    background: url("~@/assets/special/5.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem3Frame 2s forwards;

    // &:hover {
    //   background: url("~@/assets/special/55.png") no-repeat;
    //   background-size: 100% 100%;
    //   background-position: top;
    // }
  }
  .navItem5 {
    right: 10%;
    top: 42%;
    background: url("~@/assets/special/6.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem3Frame 2s forwards;

    // &:hover {
    //   background: url("~@/assets/special/66.png") no-repeat;
    //   background-size: 100% 100%;
    //   background-position: top;
    // }
  }
  .navItem6 {
    left: 12%;
    bottom: 35%;
    background: url("~@/assets/special/7.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem3Frame 2s forwards;

    // &:hover {
    //   background: url("~@/assets/special/77.png") no-repeat;
    //   background-size: 100% 100%;
    //   background-position: top;
    // }
  }
  .navItem7 {
    right: 12%;
    bottom: 35%;
    background: url("~@/assets/special/8.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem3Frame 2s forwards;

    // &:hover {
    //   background: url("~@/assets/special/88.png") no-repeat;
    //   background-size: 100% 100%;
    //   background-position: top;
    // }
  }
  .navItem8 {
    left: 16%;
    bottom: 24%;
    background: url("~@/assets/special/9.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem3Frame 2s forwards;

    // &:hover {
    //   background: url("~@/assets/special/99.png") no-repeat;
    //   background-size: 100% 100%;
    //   background-position: top;
    // }
  }
  .navItem9 {
    right: 16%;
    bottom: 24%;
    background: url("~@/assets/special/10.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem3Frame 2s forwards;

    // &:hover {
    //   background: url("~@/assets/special/101.png") no-repeat;
    //   background-size: 100% 100%;
    //   background-position: top;
    // }
  }

  // @keyframes navItem0Frame {
  //   0% {
  //     left: 50%;
  //     top: 65%;
  //     transform: translate(-50%, -50%);
  //     opacity: 0;
  //   }
  //   100% {
  //     left: 22%;
  //     top: 32%;
  //     transform: translate(0, 0);
  //     opacity: 1;
  //   }
  // }

  // @keyframes navItem1Frame {
  //   0% {
  //     right: 50%;
  //     top: 65%;
  //     transform: translate(50%, -50%);
  //     opacity: 0;
  //   }
  //   100% {
  //     right: 22%;
  //     top: 32%;
  //     transform: translate(0, 0);
  //     opacity: 1;
  //   }
  // }

  // @keyframes navItem2Frame {
  //   0% {
  //     left: 50%;
  //     bottom: 35%;
  //     transform: translate(-50%, 50%);
  //     opacity: 0;
  //   }
  //   100% {
  //     left: 15%;
  //     bottom: 18%;
  //     transform: translate(0, 0);
  //     opacity: 1;
  //   }
  // }

  // @keyframes navItem3Frame {
  //   0% {
  //     right: 50%;
  //     bottom: 35%;
  //     transform: translate(50%, 50%);
  //     opacity: 0;
  //   }
  //   100% {
  //     right: 15%;
  //     bottom: 18%;
  //     transform: translate(0, 0);
  //     opacity: 1;
  //   }
  // }

  // @keyframes growAndFadeIn {
  //   0% {
  //     width: 0;
  //     height: 0;
  //     opacity: 0;
  //     top: 65%;
  //   }
  //   100% {
  //     opacity: 1;
  //     top: 50%;
  //   }
  // }
}

@media (max-width: 1700px) {
  .specialView {
    width: 100%;

    .navActive {
      width: 300px;
      height: 92px;

      p {
        font-size: 18px;
        font-weight: 700;
        line-height: 104px;
      }
    }
  }
}

@media (max-width: 1500px) {
  .specialView {
    width: 100%;

    .navActive {
      width: 260px;
      height: 82px;
      p {
        font-size: 17px;
        font-weight: 700;
        line-height: 94px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .specialView {
    width: 100%;

    .navActive {
      width: 220px;
      height: 72px;

      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 84px;
      }
    }
  }
}
@media (max-width: 900px) {
  .specialView {
    width: 100%;

    .navActive {
      width: 220px;
      height: 72px;

      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 84px;
      }
    }

    .navItem0 {
      left: 12%;
      top: 18%;
    }
    .navItem1 {
      right: 12%;
      top: 18%;
    }
    .navItem2 {
      left: 8%;
      top: 29%;
    }
    .navItem3 {
      right: 8%;
      top: 29%;
    }
    .navItem4 {
      left: 6%;
      top: 40%;
    }
    .navItem5 {
      right: 6%;
      top: 40%;
    }
    .navItem6 {
      left: 8%;
      bottom: 35%;
    }
    .navItem7 {
      right: 8%;
      bottom: 35%;
    }
    .navItem8 {
      left: 12%;
      bottom: 24%;
    }
    .navItem9 {
      right: 12%;
      bottom: 24%;
    }
  }
}

@media (max-width: 600px) {
  .specialView {
    background-size: cover;

    .navActive {
      width: 220px;
      height: 72px;

      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 84px;
      }
    }
    .navItem0 {
      left: 0;
      top: 8%;
    }
    .navItem1 {
      right: 0;
      top: 8%;
    }
    .navItem2 {
      left: 0;
      top: 16%;
    }
    .navItem3 {
      right: 0;
      top: 16%;
    }
    .navItem4 {
      left: 0;
      top: 58%;
    }
    .navItem5 {
      right: 0;
      top: 58%;
    }
    .navItem6 {
      left: 0;
      bottom: 26%;
    }
    .navItem7 {
      right: 0;
      bottom: 26%;
    }
    .navItem8 {
      left: 0;
      bottom: 17%;
    }
    .navItem9 {
      right: 0;
      bottom: 17%;
    }
  }
}
</style>
