<template>
  <div class="main_container">
    <div class="main_container_child">
      <!-- 返回主页面 -->
      <div class="renurnHomeMain animate__animated animate__fadeInDown"></div>
      <!-- 头部标题 -->
      <div class="heade animate__animated animate__fadeInDown">
        <img class="img1" src="./img/head_gs.png" alt="" srcset="" />
        <img class="img2" src="./img/head_logo.png" alt="" srcset="" />
        <p class="heade_text">Aseem Data System</p>
      </div>
      <!-- 头部时间 -->
      <div class="timer_container animate__animated animate__fadeInDown">
        <div class="timer_container_child">
          <div class="timer_utc">
            <span>UTC时间:</span>
            {{ utcTime }}
          </div>
          <div class="timer_local">
            <span>北京时间:</span>
            {{ localTime }}
          </div>
        </div>
      </div>
      <!-- 主体导航 -->
      <div class="body_main">
        <!-- 左侧导航 -->
        <div class="left_nav_box left_nav_box1" @click.stop="goto(0)">
          空间目标持续监测系统
        </div>
        <div class="left_nav_box left_nav_box2" @click.stop="goto(1)">
          空间目标态势感知系统
        </div>
        <div class="left_nav_box left_nav_box3" @click.stop="goto(2)">过境预报系统</div>
        <div class="right_nav_box right_nav_box1" @click.stop="goto(3)">
          空间目标数据图谱系统
        </div>
        <!-- 右侧侧导航 -->
        <div class="right_nav_box right_nav_box2" @click.stop="goto(4)">碰撞预警系统</div>
        <div class="right_nav_box right_nav_box3" @click.stop="goto(5)">编目系统</div>
        <!-- 左侧圆球 -->
        <div class="left_round animate__animated animate__zoomIn">
          <div class="left_round_child">
            <img class="gear_wheel_1" src="./img/gear_wheel_1.png" alt="" />
            <img class="gear_wheel_2" src="./img/gear_wheel_2.png" alt="" />
            <p class="text">太空管理</p>
          </div>
        </div>
      </div>
      <div class="title animate__animated animate__fadeInRight">
        北京开运联合信息技术集团股份有限公司
      </div>
    </div>
  </div>
  <!-- <div class="cardHome">
    <div class="homeBG"></div>
    <video src="../assets/image/earth_v6.mp4" type="video/mp4" preload="auto" playsinline autoplay loop muted class="videos"></video>
    <div class="logo"></div>
    <span class="title">开运系统展示大厅 - 非合作空间目标管理平台</span>
    <div class="cadrList">
      <div class="itemCard" @click="goto(0)">
        <div class="bj"></div>
        <span class="text">空间目标持续监测系统</span>
      </div>
      <div class="itemCard" @click="goto(1)">
        <div class="bj"></div>
        <span class="text">空间目标态势感知系统</span>
      </div>
      <div class="itemCard" @click="goto(2)">
        <div class="bj"></div>
        <span class="text">过境预报系统</span>
      </div>
      <div class="itemCard" @click="goto(3)">
        <div class="bj"></div>
        <span class="text">空间目标数据图谱系统</span>
      </div>
      <div class="itemCard" @click="goto(4)">
        <div class="bj"></div>
        <span class="text">碰撞预警系统</span>
      </div>
    </div>
    <div class="bg" v-show="isView"></div>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      datetime: "",
      day: "",
      isView: false,
      utcTime: "",
      timer: "",
      localTime: "2022-07-07 14:52:03",
    };
  },
  created() {
    this.timer = setInterval(() => {
      this.localTime = this.formatDate("YY-MM-DD hh:mm:ss");
      this.utcTime = this.UTCFormat(this.localTime);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    // sessionStorage.removeItem('token')
    // var token = sessionStorage.getItem('token')
    // if (!token) {
    //   this.isView = true
    //   this.$alert('您当前不具备访问权限，请向上级获取账户信息登陆后访问', '提示', {
    //     confirmButtonText: '确定',
    //     callback: () => {
    //       this.$router.push('./login')
    //     }
    //   });
    // }
  },
  methods: {
    /* UTC日期格式化 */
    UTCFormat(time) {
      function checkTime(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      }
      var today = time ? new Date(time) : new Date(); //定义日期对象
      var yyyy = today.getFullYear(); //通过日期对象的getFullYear()方法返回年
      var MM = today.getMonth() + 1; //通过日期对象的getMonth()方法返回年
      var dd = today.getDate(); //通过日期对象的getDate()方法返回年
      var hh = today.getHours(); //通过日期对象的getHours方法返回小时
      var mm = today.getMinutes(); //通过日期对象的getMinutes方法返回分钟
      var ss = today.getSeconds(); //通过日期对象的getSeconds方法返回秒
      var HH = today.getHours() - 8; //通过日期对象的getSeconds方法返回秒
      MM = checkTime(MM);
      dd = checkTime(dd);
      hh = checkTime(hh);
      HH = checkTime(HH);
      mm = checkTime(mm);
      ss = checkTime(ss);
      let Time = {};
      Time["UTCTime"] = `${yyyy}-${MM}-${dd}T${HH}:${mm}:${ss}`;
      Time["Time"] = `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
      Time["SearchTime"] = `${yyyy}-${MM}-${dd} 12:00:00`;
      return Time["UTCTime"];
    },
    formatDate(fmt) {
      const date = new Date();
      const o = {
        "Y+": date.getFullYear(),
        "M+": date.getMonth() + 1, // 月
        "D+": date.getDate(), // 日
        "h+": date.getHours(), // 时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        W: date.getDay(), // 周
      };
      for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(RegExp.$1, () => {
            if (k === "W") {
              // 星期几
              const week = ["日", "一", "二", "三", "四", "五", "六"];
              return week[o[k]];
            } else if (k === "Y+" || RegExp.$1.length === 1) {
              // 年份 or 小于10不加0
              return o[k];
            } else {
              return ("00" + o[k]).substr(("" + o[k]).length); // 小于10补位0
            }
          });
        }
      }
      return fmt;
    },
    ktd() {
      this.$router.push("./home");
    },
    fhz() {
      this.$router.push("./exhibition");
    },
    goto(num) {
      if (JLCZUrl[num]) {
        window.open(`${JLCZUrl[num]}?token=${sessionStorage.getItem("token")}`);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./css/jlczhall.less");
</style>
<style>
.el-message-box {
  background: none !important;
  border: none !important;
}
.el-message-box__message,
.el-message-box__title {
  color: #fff !important;
}
.el-message-box__headerbtn {
  display: none !important;
}
</style>
