import * as TYPES from "../action-types";

let mutations = {
    [TYPES.ADDING_ATTRIBUTE](state, val) {
        state.attributeData = val;
    },
    [TYPES.SET_TABINDEX](state, val) {
        state.tabIndex = val;
    },
    [TYPES.INIT_CZMLDATA](state, val) {
        state.czmlData = val;
    },
    [TYPES.INIT_MAINTARGETNAME](state, val) {
        state.MainTargetName = val;
    },
    [TYPES.INIT_FROMTARGETNAME](state, val) {
        state.FromTargetName = val;
    },
    [TYPES.INIT_MAINTARGETID](state, val) {
        state.MainTargetId = val;
    },
    [TYPES.INIT_FROMTARGETID](state, val) {
        state.FromTargetId = val;
    },
}
export default mutations;