<!--
 * @Author: Suroc
 * @Date: 2024-05-29 14:03:37
 * @LastEditTime: 2024-06-03 14:03:12
 * @Description: 科研院所
-->
<template>
  <div class="securityView">
    <a
      :href="item.path"
      target="_blank"
      class="navActive"
      :class="`navItem${I}`"
      v-for="(item, I) in navList"
      :key="I"
    >
      <p>{{ item.name }}</p>
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "太空目标态势分析",
          path: "https://ssa.aseem.cn/",
        },
        {
          name: "太空目标碰撞预报",
          path: "https://ads.aseem.cn/pzyj/#/",
        },
        {
          name: "太空目标过境分析",
          path: "https://ads.aseem.cn/visualization/transit/v3/",
        },
        {
          name: "太空碰撞热力图分析",
          path: "https://ads.aseem.cn/visualization/approach/v1",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.securityView {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("~@/assets/research/1.gif") no-repeat center center;
  background-size: 100% 100%;
  position: relative;

  .navActive {
    width: 300px;
    height: 300px;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    background: url("~@/assets/research/1.png") no-repeat center;
    background-size: 100% 100%;
    opacity: 0;

    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 90px;
      text-align: center;
      -webkit-text-fill-color: transparent;
      background: linear-gradient(180deg, #fdfffe 0%, #69caf7 100%);
      -webkit-background-clip: text;
    }

    &:hover {
      background: url("~@/assets/research/2.png") no-repeat;
      background-size: 100% 100%;
      background-position: top;
      transform: scale(1.06);

      p {
        -webkit-text-fill-color: transparent;
        background: linear-gradient(180deg, #00ffaa 0%, #00ffff 100%);
        -webkit-background-clip: text;
      }
    }
  }

  .navItem0 {
    left: 12%;
    top: 22%;
    animation: navItem0Frame 0.5s 0.5s forwards;
  }
  .navItem1 {
    right: 12%;
    top: 22%;
    animation: navItem1Frame 0.5s 1s forwards;
  }
  .navItem2 {
    left: 18%;
    bottom: 4%;
    animation: navItem2Frame 0.5s 1.5s forwards;
  }
  .navItem3 {
    right: 18%;
    bottom: 4%;
    animation: navItem3Frame 0.5s 2s forwards;
  }

  @keyframes navItem0Frame {
    0% {
      left: 50%;
      top: 65%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
    100% {
      left: 12%;
      top: 22%;
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes navItem1Frame {
    0% {
      right: 50%;
      top: 65%;
      transform: translate(50%, -50%);
      opacity: 0;
    }
    100% {
      right: 12%;
      top: 22%;
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes navItem2Frame {
    0% {
      left: 50%;
      bottom: 35%;
      transform: translate(-50%, 50%);
      opacity: 0;
    }
    100% {
      left: 18%;
      bottom: 4%;
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes navItem3Frame {
    0% {
      right: 50%;
      bottom: 35%;
      transform: translate(50%, 50%);
      opacity: 0;
    }
    100% {
      right: 18%;
      bottom: 4%;
      transform: translate(0, 0);
      opacity: 1;
    }
  }
}

@media (max-width: 1700px) {
  .securityView {
    width: 100%;

    .navActive {
      width: 260px;
      height: 260px;

      p {
        font-size: 22px;
        font-weight: 700;
        line-height: 80px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .securityView {
    width: 100%;

    .navActive {
      width: 240px;
      height: 240px;

      p {
        font-size: 20px;
        font-weight: 700;
        line-height: 70px;
      }
    }
  }
}
@media (max-width: 900px) {
  .securityView {
    width: 100%;

    .navActive {
      width: 180px;
      height: 180px;

      p {
        font-size: 18px;
        font-weight: 700;
        line-height: 54px;
      }
    }

    .navItem0 {
      left: 6% !important;
      top: 22% !important;
    }
    .navItem1 {
      right: 6% !important;
      top: 22% !important;
    }
    .navItem2 {
      left: 12% !important;
      bottom: 4% !important;
    }
    .navItem3 {
      right: 12% !important;
      bottom: 4% !important;
    }
  }
}
@media (max-width: 600px) {
  .securityView {
    background-size: cover;

    .navActive {
      width: 160px;
      height: 160px;

      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 50px;
      }
    }

    .navItem0 {
      top: 12% !important;
    }
    .navItem1 {
      top: 18% !important;
    }
    .navItem2 {
      top: 78% !important;
    }
    .navItem3 {
      top: 84% !important;
    }
  }
}
</style>
