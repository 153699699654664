/*
 * @Author: Suroc
 * @Date: 2023-03-10 11:04:37
 * @LastEditTime: 2024-06-05 10:06:45
 * @Description: 
 */
import Vue from "vue"; //引入Vue
import Router from "vue-router"; //引入vue-router
import WebHall from "./views/webhall.vue";
import OldHall from "./views/oldhall.vue";
import HallJLCZ from "./views/jlczhall.vue";
import LoGin from "./views/login.vue";
import Security from "./views/security.vue";
import Research from "./views/research.vue";
import Teach from "./views/teach.vue";
import Special from "./views/special.vue";

Vue.use(Router); //Vue全局使用Router

export default new Router({
  routes: [
    {
      path: "/",
      name: "index",
      component: WebHall,
    },
    {
      path: "/teach",
      name: "Teach",
      component: Teach,
    },
    {
      path: "/special",
      name: "Special",
      component: Special,
    },
    {
      path: "/research",
      name: "Research",
      component: Research,
    },
    {
      path: "/security",
      name: "Security",
      component: Security,
    },
    {
      path: "/webhall",
      name: "WebHall",
      component: WebHall,
    },
    {
      path: "/v1",
      name: "oldHall",
      component: OldHall,
    },
    {
      path: "/v2",
      name: "HallJLCZ",
      component: HallJLCZ,
    },
    {
      path: "/login",
      name: "LoGin",
      component: LoGin,
    },
  ],
});
