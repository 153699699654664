<!--
 * @Author: Suroc
 * @Date: 2024-05-29 14:03:37
 * @LastEditTime: 2024-06-03 15:19:34
 * @Description: 国家安全
-->
<template>
  <div class="securityView">
    <div class="earthBox">
      <div class="earth"></div>
    </div>
    <a :href="item.path" target="_blank" class="navActive" :class="`navItem${I}`" v-for="(item, I) in navList" :key="I">
      <p>{{ item.name }}</p>
    </a>
  </div>
</template>
<script>
export default {
  data () {
    return {
      navList: [
        {
          name: "空间目标波束覆盖检测",
          path: "https://ads.aseem.cn/digit/bsjc",
        },
        {
          name: "太空态势感知",
          path: "https://ssa.aseem.cn/",
        },
        {
          name: "重点目标星下点轨迹",
          path: "https://ads.aseem.cn/digit/xxd",
        },
        {
          name: "重点目标过境分析",
          path: "https://ads.aseem.cn/visualization/transit/v3/",
        },
      ],
    };
  },
  mounted () { },
  methods: {},
};
</script>

<style scoped lang="less">
.securityView {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("~@/assets/security/1.gif") no-repeat center center;
  background-size: 100% 100%;
  position: relative;

  .earthBox {
    width: 42%;
    height: 64%;
    position: absolute;
    left: 50%;
    top: 54%;
    transform: translate(-50%, -50%);
    background: url("~@/assets/security/1.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;

    .earth {
      position: absolute;
      top: 44%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 500px;
      height: 500px;
      background: url("~@/assets/security/2.png") no-repeat;
      background-size: 100% 100%;
      background-position: top;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 20%;
      transform: translate(-50%, 0);
      width: 290px;
      height: 120px;
      background: url("~@/assets/security/4.png") no-repeat;
      background-size: 100% 100%;
      background-position: top;
      animation: flash 1.2s infinite;
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 18%;
      transform: translate(-50%, 0);
      width: 360px;
      height: 180px;
      background: url("~@/assets/security/3.png") no-repeat;
      background-size: 100% 100%;
      background-position: top;
      animation: flash 1.2s infinite alternate;
    }

    @keyframes flash {
      0%,
      50% {
        opacity: 0.5;
      }
      50%,
      100% {
        opacity: 1;
      }
    }
  }

  .navActive {
    width: 500px;
    height: 140px;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    opacity: 0;

    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 160px;
      text-align: center;
      -webkit-text-fill-color: transparent;
      background: linear-gradient(180deg, #fdfffe 0%, #69caf7 100%);
      -webkit-background-clip: text;
    }

    &:hover {
      p {
        -webkit-text-fill-color: transparent;
        background: linear-gradient(180deg, #00ffaa 0%, #00ffff 100%);
        -webkit-background-clip: text;
      }
    }
  }

  .navItem0 {
    background: url("~@/assets/security/6.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    left: 12%;
    top: 26%;
    animation: navItem0Frame 0.5s 0.5s forwards;

    &:hover {
      background: url("~@/assets/security/10.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .navItem1 {
    background: url("~@/assets/security/7.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    right: 12%;
    top: 26%;
    animation: navItem1Frame 0.5s 1s forwards;

    &:hover {
      background: url("~@/assets/security/11.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .navItem2 {
    background: url("~@/assets/security/8.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    left: 12%;
    top: 55%;
    animation: navItem2Frame 0.5s 1.5s forwards;

    &:hover {
      background: url("~@/assets/security/12.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .navItem3 {
    background: url("~@/assets/security/9.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    right: 12%;
    top: 55%;
    animation: navItem3Frame 0.5s 2s forwards;

    &:hover {
      background: url("~@/assets/security/13.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  @keyframes navItem0Frame {
    0% {
      left: -100%;
      top: 26%;
      opacity: 0;
    }
    100% {
      left: 12%;
      top: 26%;
      opacity: 1;
    }
  }

  @keyframes navItem1Frame {
    0% {
      right: -100%;
      top: 26%;
      opacity: 0;
    }
    100% {
      right: 12%;
      top: 26%;
      opacity: 1;
    }
  }

  @keyframes navItem2Frame {
    0% {
      left: -100%;
      top: 55%;
      opacity: 0;
    }
    100% {
      left: 12%;
      top: 55%;
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes navItem3Frame {
    0% {
      right: -100%;
      top: 55%;
      opacity: 0;
    }
    100% {
      right: 12%;
      top: 55%;
      opacity: 1;
    }
  }
}

@media (max-width: 1800px) {
  .securityView {
    width: 100%;

    .earthBox {
      .earth {
        position: absolute;
        top: 44%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 440px;
        height: 440px;
        background: url("~@/assets/security/2.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 20%;
        transform: translate(-50%, 0);
        width: 290px;
        height: 120px;
        background: url("~@/assets/security/4.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
        animation: flash 1.2s infinite;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 18%;
        transform: translate(-50%, 0);
        width: 360px;
        height: 180px;
        background: url("~@/assets/security/3.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
        animation: flash 1.2s infinite alternate;
      }

      @keyframes flash {
        0%,
        50% {
          opacity: 0.5;
        }
        50%,
        100% {
          opacity: 1;
        }
      }
    }

    .navActive {
      width: 440px;
      height: 130px;

      p {
        font-size: 24px;
        font-weight: 700;
        line-height: 150px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .securityView {
    width: 100%;

    .earthBox {
      .earth {
        position: absolute;
        top: 44%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        height: 400px;
        background: url("~@/assets/security/2.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 20%;
        transform: translate(-50%, 0);
        width: 240px;
        height: 120px;
        background: url("~@/assets/security/4.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
        animation: flash 1.2s infinite;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 18%;
        transform: translate(-50%, 0);
        width: 310px;
        height: 180px;
        background: url("~@/assets/security/3.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
        animation: flash 1.2s infinite alternate;
      }

      @keyframes flash {
        0%,
        50% {
          opacity: 0.5;
        }
        50%,
        100% {
          opacity: 1;
        }
      }
    }

    .navActive {
      width: 400px;
      height: 120px;

      p {
        font-size: 22px;
        font-weight: 700;
        line-height: 140px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .securityView {
    width: 100%;

    .earthBox {
      .earth {
        position: absolute;
        top: 44%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 360px;
        height: 360px;
        background: url("~@/assets/security/2.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 20%;
        transform: translate(-50%, 0);
        width: 220px;
        height: 120px;
        background: url("~@/assets/security/4.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
        animation: flash 1.2s infinite;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 18%;
        transform: translate(-50%, 0);
        width: 280px;
        height: 180px;
        background: url("~@/assets/security/3.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
        animation: flash 1.2s infinite alternate;
      }

      @keyframes flash {
        0%,
        50% {
          opacity: 0.5;
        }
        50%,
        100% {
          opacity: 1;
        }
      }
    }

    .navActive {
      width: 340px;
      height: 100px;

      p {
        font-size: 18px;
        font-weight: 700;
        line-height: 120px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .securityView {
    width: 100%;

    .earthBox {
      .earth {
        position: absolute;
        top: 44%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        height: 300px;
        background: url("~@/assets/security/2.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 20%;
        transform: translate(-50%, 0);
        width: 160px;
        height: 120px;
        background: url("~@/assets/security/4.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
        animation: flash 1.2s infinite;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 18%;
        transform: translate(-50%, 0);
        width: 200px;
        height: 180px;
        background: url("~@/assets/security/3.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
        animation: flash 1.2s infinite alternate;
      }

      @keyframes flash {
        0%,
        50% {
          opacity: 0.5;
        }
        50%,
        100% {
          opacity: 1;
        }
      }
    }

    .navActive {
      width: 280px;
      height: 80px;

      p {
        font-size: 17px;
        font-weight: 700;
        line-height: 90px;
      }
    }
  }
}

@media (max-width: 900px) {
  .securityView {
    width: 100%;

    .earthBox {
      .earth {
        position: absolute;
        top: 44%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 220px;
        height: 220px;
        background: url("~@/assets/security/2.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 20%;
        transform: translate(-50%, 0);
        width: 120px;
        height: 120px;
        background: url("~@/assets/security/4.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
        animation: flash 1.2s infinite;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 18%;
        transform: translate(-50%, 0);
        width: 160px;
        height: 180px;
        background: url("~@/assets/security/3.png") no-repeat;
        background-size: 100% 100%;
        background-position: top;
        animation: flash 1.2s infinite alternate;
      }

      @keyframes flash {
        0%,
        50% {
          opacity: 0.5;
        }
        50%,
        100% {
          opacity: 1;
        }
      }
    }

    .navActive {
      width: 240px;
      height: 70px;

      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 80px;
      }
    }
  }
}

@media (max-width: 600px) {
  .securityView {
    background-size: cover;

    .earthBox {
      position: absolute;
      top: 44%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 400px;
      height: 400px;

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 20%;
        transform: translate(-50%, 0);
        width: 70px;
        height: 120px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 18%;
        transform: translate(-50%, 0);
        width: 90px;
        height: 180px;
      }

      @keyframes flash {
        0%,
        50% {
          opacity: 0.5;
        }
        50%,
        100% {
          opacity: 1;
        }
      }
    }

    .navActive {
      width: 300px;
      height: 80px;

      p {
        font-size: 12px;
        font-weight: 700;
        line-height: 90px;
        text-align: center;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(180deg, #fdfffe 0%, #69caf7 100%);
        -webkit-background-clip: text;
      }
    }

    .navItem0 {
      top: 8% !important;
    }
    .navItem1 {
      top: 18% !important;
    }
    .navItem2 {
      top: 58% !important;
    }
    .navItem3 {
      top: 68% !important;
    }
  }
}
</style>
