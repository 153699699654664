import Vue from "vue";
import Vuex from "vuex";
import logger from "vuex/dist/logger";
import mutations from "./mutatinos/mutations";
Vue.use(Vuex);

let state = {
    tabIndex: 4,
    attributeData: [],
    user: {
        userName: "admin",
        password: 111111
    },
    token: "",
    MainTargetName:'', //主目标名称
    FromTargetName:'', //从目标名称
    MainTargetId:'', //主目标id
    FromTargetId:'', //从目标id
    czmlData:[],// czml数据
};
let getters = {
    getAttribute(state) {
        return state.attributeData
    }
};

export default new Vuex.Store({
    state,
    mutations,
    getters,
    plugins: [logger()],
    strict: true
})