import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui'
import router from './router.js'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import animated from 'animate.css' // npm install animate.css --save安装，再引入

Vue.config.productionTip = false
Vue.use(Antd);
Vue.use(animated)
Vue.use(ElementUI);
import store from "./store";
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')