<!--
 * @Author: Suroc
 * @Date: 2024-05-29 14:03:37
 * @LastEditTime: 2024-06-03 17:43:38
 * @Description: 科研院所
-->
<template>
  <div class="teachView">
    <a
      :href="item.path"
      target="_blank"
      class="navActive"
      :class="`navItem${I}`"
      v-for="(item, I) in navList"
      :key="I"
    >
      <p>{{ item.name }}</p>
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "太空卫星态势分析",
          path: "https://ssa.aseem.cn/",
        },
        {
          name: "太空态势溯源分析",
          path: "https://ads.aseem.cn/visualization/deduce/v1",
        },
        {
          name: "太空卫星轨道仿真",
          path: "https://ads.aseem.cn/visualization/track/v2",
        },
        {
          name: "太空卫星星地链接",
          path: "https://ads.aseem.cn/digit/xdlj",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.teachView {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("~@/assets/teach/1.gif") no-repeat center center;
  background-size: cover;
  background-position: top;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url("~@/assets/teach/2.gif") no-repeat center center;
    background-size: cover;
    background-position: top;
    animation: growAndFadeIn 2s forwards;
  }

  .navActive {
    width: 300px;
    height: 102px;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    background: url("~@/assets/teach/1.png") no-repeat center;
    background-size: 100% 100%;
    opacity: 0;
    z-index: 2;

    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 102px;
      text-align: center;
      -webkit-text-fill-color: transparent;
      background: linear-gradient(180deg, #fdfffe 0%, #69caf7 100%);
      -webkit-background-clip: text;
    }

    &:hover {
      transform: scale(1.06);

      p {
        -webkit-text-fill-color: transparent;
        background: linear-gradient(180deg, #00ffaa 0%, #00ffff 100%);
        -webkit-background-clip: text;
      }
    }
  }

  .navItem0 {
    left: 22%;
    top: 32%;
    background: url("~@/assets/teach/2.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem0Frame 2s forwards;

    &:hover {
      background: url("~@/assets/teach/4.png") no-repeat;
      background-size: 100% 100%;
      background-position: top;
    }
  }
  .navItem1 {
    right: 22%;
    top: 32%;
    animation: navItem1Frame 2s forwards;

    &:hover {
      background: url("~@/assets/teach/3.png") no-repeat;
      background-size: 100% 100%;
      background-position: top;
    }
  }
  .navItem2 {
    left: 15%;
    bottom: 18%;
    background: url("~@/assets/teach/2.png") no-repeat;
    background-size: 100% 100%;
    background-position: top;
    animation: navItem2Frame 2s forwards;

    &:hover {
      background: url("~@/assets/teach/4.png") no-repeat;
      background-size: 100% 100%;
      background-position: top;
    }
  }
  .navItem3 {
    right: 15%;
    bottom: 18%;
    animation: navItem3Frame 2s forwards;

    &:hover {
      background: url("~@/assets/teach/3.png") no-repeat;
      background-size: 100% 100%;
      background-position: top;
    }
  }

  @keyframes navItem0Frame {
    0% {
      left: 50%;
      top: 65%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
    100% {
      left: 22%;
      top: 32%;
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes navItem1Frame {
    0% {
      right: 50%;
      top: 65%;
      transform: translate(50%, -50%);
      opacity: 0;
    }
    100% {
      right: 22%;
      top: 32%;
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes navItem2Frame {
    0% {
      left: 50%;
      bottom: 35%;
      transform: translate(-50%, 50%);
      opacity: 0;
    }
    100% {
      left: 15%;
      bottom: 18%;
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes navItem3Frame {
    0% {
      right: 50%;
      bottom: 35%;
      transform: translate(50%, 50%);
      opacity: 0;
    }
    100% {
      right: 15%;
      bottom: 18%;
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes growAndFadeIn {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
      top: 65%;
    }
    100% {
      opacity: 1;
      top: 50%;
    }
  }
}

@media (max-width: 1700px) {
  .teachView {
    width: 100%;

    .navActive {
      width: 260px;
      height: 92px;

      p {
        font-size: 20px;
        font-weight: 700;
        line-height: 92px;
      }
    }
  }
}
@media (max-width: 1400px) {
  .teachView {
    width: 100%;

    .navActive {
      width: 220px;
      height: 82px;

      p {
        font-size: 18px;
        font-weight: 700;
        line-height: 82px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .teachView {
    width: 100%;

    .navActive {
      width: 180px;
      height: 72px;

      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 72px;
      }
    }
  }
}
@media (max-width: 900px) {
  .teachView {
    width: 100%;

    .navActive {
      width: 140px;
      height: 62px;

      p {
        font-size: 12px;
        font-weight: 700;
        line-height: 62px;
      }
    }
  }
}
@media (max-width: 600px) {
  .teachView {
    background-size: cover;

    .navActive {
      width: 200px;
      height: 82px;

      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 82px;
      }
    }

    .navItem0 {
      top: 9% !important;
    }
    .navItem1 {
      top: 21% !important;
    }
    .navItem2 {
      top: 74% !important;
    }
    .navItem3 {
      top: 86% !important;
    }
  }
}
</style>
